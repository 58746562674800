<template>
	<div class="container-register-sleep">
	<SWNavButton
		@click="$router.push({ name: 'forgot' })" 
		class="btn-nav-ragister"
		:size="'small'"
		>Back</SWNavButton
	>
	<div class="box-register-sleep">
		<div class="img-sleep-register">
			<img src="../../assets/img/sleepNewLogo.png" alt="" />
		</div>

		<div class="title-sleep-register">
			<p>Enter code from SMS</p>
			<p class="subtitle">We’ll send code by SMS for resetting your password.</p>
		</div>

		<form @submit.prevent="submitHandler">
			<div class="register-input">

				<SWInput
                  class="margin-inp"
                  placeholder="Code from SMS"
                  v-model="code"
                  type="input"
                  @input="$v.code.$touch()"
                  :errorMessage="codeError"
                  :isError="isCodeError"
                  :hasShowIcon="true"
               ></SWInput>
			
			<SWButton :disabled="disabled" type="submit" variant="primari" size="big"
				>Continue</SWButton
			>
			</div>
		</form>

		<div class="title-sleep-register">
			<button :disabled="isResendDisabled" class="subtitle resend" @click="sendSMS"><u>Resend the code again {{ timeFormat ? `in${timeFormat}` : '' }}</u></button>
		</div>

		

		<div v-if="support" class="title-sleep-support">
			<p class="subtitle">If you still haven't received a code, but you are sure that the phone number is correct, please contact us:</p>
			<p class="subtitle">support@sleep-well.coach</p>
			<p class="subtitle">+38 044 256 4556</p>
		</div>

		<!-- <router-view></router-view> -->
	</div>
	<Notification :show="notificationShowed" :text="notificationText" :color="notificationColor" @closeNotification="closeNotification" />

 </div>
 </template>
 
 <script>
 import {SWNavButton, SWButton, SWInput } from 'sw-ui-kit';
 import { validationMixin } from 'vuelidate';
 import { required } from 'vuelidate/lib/validators';
 import { hasEightDigitCode } from '../../validation/validation';
 import * as Sentry from '@sentry/vue';
 import Notification from '../../components/UI/Notification.vue';

 const TIME_COUNT_FROM = 60 * 1000;

 export default {

 components: {
	SWNavButton,
	SWButton,
	SWInput,
	Notification
 },
 mixins: [validationMixin],
 

 data(){
	return {
		phone: '',
		code: '',
		support: false,
		timeLeft: 0,
		intervalId: null,
		timeStarted: null,
		isResendDisabled: true,
		notificationText: '',
		notificationColor: '',
		notificationShowed: false
	}
 },
 validations: {
	code: { required, hasEightDigitCode },
 },

async mounted(){
	console.log(this.$route.params.phone)
	this.phone = this.$route.params.phone;
	if(!this.phone){
		return this.$router.push({ name: 'forgot' });
	}

		setTimeout(() => {
			this.support = true;
		}, 15000);

		this.startTimer();

		await this.sendSMS();
	},

	methods: {
		showNotification(text, color) {
			console.log('show notification')
			this.notificationShowed = true;
			this.notificationText = text;
			this.notificationColor = color;
		},
		closeNotification() {
			this.notificationShowed = false;
			this.$router.push({ name: 'forgot'});
		},
		async submitHandler(){
			this.$router.push({ name: `recovery`, params: { method: 'phone', methodValue: this.phone }, query: { verify_token: this.code } });
		},
		startTimer() {
			this.timeLeft = TIME_COUNT_FROM;
			this.isResendAllwed = false;
			this.intervalId = setInterval(() => {
				if (!this.startTime) {
					this.startTime = Date.now();
				}
				this.timeLeft = Date.now() - this.startTime - TIME_COUNT_FROM;
				if (Date.now() - this.startTime >= TIME_COUNT_FROM) {
					this.stopTimer();
				}
			}, 500);
		},

		stopTimer() {
			clearInterval(this.intervalId);
			this.timeLeft = 0;
			this.startTime = null;
			this.isResendDisabled = false;
		},

		async sendSMS(){
			try{
				await this.$http.post(`/auth/forgot/get/phone`, { phone: this.phone });
			} catch(e){
				console.log(e)
				Sentry.captureException(e);
				alert(`${e?.data?.message}`);
				return this.$router.push({ name: 'forgot' });
			}
		},

/* 		async resendSMS() {
			try {
				await this.$http.post(
					'auth/register/resend/phone',
					{
						phone: this.phone,
					},
				);
				this.startTimer();
			} catch {
				Sentry.captureException('SMS sending limit per day exceeded.');
				alert('SMS sending limit per day exceeded.');
			}
		}, */
	},
	computed:{
		disabled() {
			return this.codeError != false;
		},
		isCodeError() {
			if(this.codeError != ''){
				return true;
			}
			return false;
		},
		codeError() {
			if (!this.$v.code.required) return 'The field must be filled';
			if (!this.$v.code.hasEightDigitCode)
				return 'The code must be 8 digits long';
			return '';
		},
		timeFormat() {
			if (!this.timeLeft) return null;
			const totalSeconds = Math.ceil(Math.abs(this.timeLeft) / 1000);
			const minutes = Math.floor(totalSeconds / 60);
			const seconds = totalSeconds % 60;

			return `${minutes.toString().length === 1 ? `0${minutes ? minutes : 0}` : minutes}:${
				seconds.toString().length === 1 ? '0' + seconds : seconds
			}`;
		},
	}
 };
 </script>

<style lang="sass" scoped>
.name-registar
	display: flex
	min-width: 175px
	width: fit-content
	margin-bottom: 15px
.corent-margin
	margin-right: 8px
.reg-box
	display: flex
	justify-content: center
.reg-box:nth-child(1)
	margin-right: 20px

.reg-box
	@media screen and (max-width:380px)
		margin-top: -10px
.btn-nav-ragister
	text-align: left
.box-register-sleep
	max-width: 356px
	margin: 0 auto
.container-register-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 15px 20px
	display: flex
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px
.img-sleep-register
	max-width: 128px
	margin: 0 auto
	padding-top: 30px
	padding-bottom: 24px
	@media screen and (max-width:380px)
		padding-bottom: 10px
		padding-top: 10px

.title-sleep-register
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center
.title-sleep-support
	text-align: left
.subtitle
	font-family: 'Roboto', sans-serif
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	color: #11151F
	margin-top: 16px
	margin-bottom: 16px
.resend
	font-weight: 400
	font-size: 12px
	line-height: 15.6px
	color: #11151F

.paragraf-register
	font-size: 14px
	font-weight: 500
	line-height: 18.2px
	text-align: center
	margin-top: 16px
	margin-bottom: 16px
	@media screen and (max-width:380px)
		margin-bottom: 5px
.paragraf-current
	font-weight: 500
	line-height: 18.2px
	text-align: center
	color: #828282
	cursor: pointer
	padding: 15px
.active
	color: #11151F
	text-decoration: underline
.paragraf-current-one
	font-weight: 500
	line-height: 18.2px
	text-align: center
	padding-top: 15px
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px

.button-social
	display: flex
	justify-content: space-between
.btn-reg
	width: 100%
	font-size: 14px
	font-weight: 400
.btn-reg:nth-child(2)
	margin-right: 10px
	margin-left: 10px
.register-input
	margin-bottom: 32px
	@media screen and (max-width:380px)
		margin-bottom: 10px
.margin-inp
	margin-bottom: 16px
.btn-nav-ragister
	margin-bottom: 20px
	font-weight: 400
	margin-top: 64px

@media screen and (max-width:1024px)
	.btn-nav-ragister
		margin-top: 16px
</style>